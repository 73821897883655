<mat-card
  [ngClass]="{
    'upload-screen': !fileOver,
    'upload-screen fileOver': fileOver,
    'upload-screen file-selected': fileInfo,
  }"
>
  <mat-card-content>
    <div class="upload-field">
      <input
        #fileInput
        id="uploadFile"
        name="uploadFile"
        class="form-control"
        type="file"
        (change)="onChange($event)"
        accept=".csv,.xlsx"
      />
      <mat-icon
        mat-list-icon
        class="upload-icon"
        fontSet="material-icons-outlined"
        >cloud_upload</mat-icon
      >
      <mat-form-field subscriptSizing="dynamic">
        <mat-toolbar>
          <input matInput [(ngModel)]="textPrompt" readonly name="name" />
        </mat-toolbar>
      </mat-form-field>
    </div>
  </mat-card-content>
</mat-card>
<div class="spinner">
  <app-spinner
    *ngIf="loading"
    mode="indeterminate"
    number="100"
    width="50px"
  ></app-spinner>
</div>

<div class="submit-container" *ngIf="fileInfo">
  <div class="certify-batch">
    <mat-checkbox
      [(ngModel)]="isCertified"
      color="primary"
      [disabled]="!this.fileIsValid"
      >Certify Batch Request</mat-checkbox
    >
    <mat-icon
      class="info-icon"
      [matTooltip]="tooltipInfo"
      matTooltipPosition="right"
      >info</mat-icon
    >
  </div>
  <button
    id="upload-file"
    (click)="onUpload()"
    color="primary"
    mat-raised-button
    [disabled]="
      !this.fileInfo || !this.isCertified || this.loading || !this.fileIsValid
    "
  >
    Upload File
  </button>
  <table
    mat-table
    *ngIf="displayFileContents.length"
    [dataSource]="displayFileContents"
    class="mat-elevation-z8"
  >
    <ng-container
      *ngFor="let column of computedHeaders"
      matColumnDef="{{ column }}"
    >
      <th mat-header-cell *matHeaderCellDef>
        {{ column === 'rowNumber' ? 'Line #' : column }}
      </th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="!element.dataErrors?.[column]; else elseBlock">
          <!-- TODO: deduplicate this -->
          {{ column === 'rowNumber' ? element.row : element.data[column] }}
        </ng-container>
        <ng-template #elseBlock>
          {{ column === 'rowNumber' ? element.row : element.data[column] }}
          <mat-icon
            class="error-icon"
            [matTooltip]="element.dataErrors?.[column]"
            matTooltipPosition="right"
            *ngIf="element.dataErrors?.[column]"
            >error</mat-icon
          >
        </ng-template>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="computedHeaders"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: computedHeaders"
      [class]="{ invalid: !!row.dataErrors }"
    ></tr>
  </table>
</div>
