import { Component, OnInit } from '@angular/core';
import {
  FileContentValidationResult,
  FileUploadEvent,
  FileUploadEventData,
} from 'src/app/components/file-upload/file-upload.component';
import { WorkSheet, utils, writeFile, WorkBook } from 'xlsx';
import { RAGApiService } from 'src/app/services/rag-api/rag-api.service';
import {
  SpreadsheetExclusionColumnNames,
  SpreadsheetExclusionRow,
  spreadsheetToExclusionRow,
} from 'src/app/models/spreadsheet-exclusion-row.model';
import { UserInfoService } from 'src/app/services/userinfo/userinfo.service';
import {
  OktaJwtDto,
  PlatformType,
  convertToPlatformType,
} from '@wex-risk/rp-portfolio-utils';

@Component({
  selector: 'app-exclusion',
  templateUrl: './exclusion.component.html',
  styleUrls: ['./exclusion.component.scss'],
})
// Accounts exclusions page
export class ExclusionComponent implements OnInit {
  readonly headers = Object.values(SpreadsheetExclusionColumnNames);

  constructor(
    private ragService: RAGApiService,
    private userService: UserInfoService,
  ) {}

  user?: OktaJwtDto;

  //sets the user
  ngOnInit(): void {
    this.userService.user.subscribe((user) => {
      this.user = user;
    });
  }

  //Converts file contents to SpreadsheetExclusionRow and submits to the RAG API
  async runUpload({
    fileName,
    fileContents,
    fileRows,
  }: FileUploadEventData<SpreadsheetExclusionRow>) {
    await this.ragService.submitExclusions({
      submissionName: fileName,
      rowCount: fileRows,
      rows: fileContents.map((row) => spreadsheetToExclusionRow(row.data)),
    });
  }

  /**
   * Validates a row from the spreadsheet containing exclusion data.
   *
   * @param fileRow - The row from the spreadsheet to validate.
   * @returns An object containing the validation result and any errors found.
   */
  validateRow = (
    fileRow: SpreadsheetExclusionRow,
  ): FileContentValidationResult<SpreadsheetExclusionRow> => {
    const { platform: platformString, account_number, account_group } = fileRow;
    const errorMap = new Map<keyof SpreadsheetExclusionRow, string[]>();
    let platform: PlatformType | undefined;
    try {
      platform = convertToPlatformType(platformString);
    } catch (e) {
      errorMap.set(SpreadsheetExclusionColumnNames.platform, [
        `platform must be either '${PlatformType.naf}' or '${PlatformType.otr}'`,
      ]);
    }

    if (!account_group || account_group.length === 0) {
      errorMap.set(SpreadsheetExclusionColumnNames.account_group, [
        'account_group cannot be empty',
      ]);
    }

    const nafAccountNumberRegex = /^\d+$/;
    const otrAccountNumberRegex = /^[a-zA-Z0-9]+$/;

    if (!account_number || account_number.length === 0) {
      errorMap.set(SpreadsheetExclusionColumnNames.account_number, [
        'account_number cannot be empty',
      ]);
    } else if (
      platform === PlatformType.naf &&
      !nafAccountNumberRegex.test(account_number ?? '')
    ) {
      errorMap.set(SpreadsheetExclusionColumnNames.account_number, [
        'account_number must contain only numbers for NAF accounts',
      ]);
    } else if (
      platform === PlatformType.otr &&
      !otrAccountNumberRegex.test(account_number ?? '')
    ) {
      errorMap.set(SpreadsheetExclusionColumnNames.account_number, [
        'account_number must contain only numbers or letters for OTR accounts',
      ]);
    }
    return {
      valid: errorMap.size === 0,
      errorMap: new Map(
        Array.from(errorMap.entries()).map(([key, value]) => [
          key,
          value.join('\n'),
        ]),
      ),
    };
  };

  //dataSubmitted event handler
  async onDataSubmitted(event: FileUploadEvent<SpreadsheetExclusionRow>) {
    await this.runUpload(event)
      .then(() => event.onFinish())
      .catch(event.onFinish);
  }

  //generates template file
  exportTemplate() {
    const fileName = 'account_exclusions_template.xlsx';

    const ws: WorkSheet = utils.json_to_sheet([], {
      header: this.headers,
    });
    const wb: WorkBook = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Account exclusions template');
    writeFile(wb, fileName);
  }
}
